import React, { useState } from 'react';
import InfoPopup from './popups/infoPopup';

export default function WhatIsTraceIdButton() {
    const [showPopup, setShowPopup] = useState(false);

    const closePopup = () => {
        setShowPopup(false);
    };
    return (
        <>
            {showPopup && <InfoPopup closeHandler={closePopup} title={'What is a Trace ID?'} info={'The Trace ID is the payment ID number you would give to your loan servicer so they can track an individual payment.<br/><br/>Let them know this is created by the Mastercard RPPS Bill Pay Network so that all parties can track the payment.'} />}
            <span onClick={() => { setShowPopup(true) }} className={'clickable tooltip-container'}>
                <img src={'/images/question-blue.svg'} alt={''} />
                <span className={'tooltip'} style={{width:'fit-content',maxWidth:300,whiteSpace:'nowrap'}}>
                    What is a Trace ID?
                </span>
            </span>
        </>
    )

}
