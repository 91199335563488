import React, {useEffect, useState} from 'react';
import {CSSTransition} from "react-transition-group";
import FileInput from "../../components/fileInput";
import axios from "axios";
import {useStoreActions, useStoreState} from "easy-peasy";
import CompanyInformationPopup from "../../components/popups/companyInformationPopup";
import OwnerInformationPopup from "../../components/popups/ownerInformationPopup";
import {baseApiUrl} from "../../_constants";
import InfiniteScroll from "react-infinite-scroller";
import DebitsList from "../../components/lists/debitsList";
import {useParams} from "react-router-dom";
import AdpDetails from "../../components/adpDetails";
import Tabs from '../../components/tabs';
import DebitReportForm from "../../components/debitReportForm";

export default function CompanyAccountScreen(){

	const {error} = useParams();
	const [updateKey,setUpdateKey] = useState(Math.random());
	const activeOrganization = useStoreState(state=>state.user.activeOrganization);
	const uploadUrl = '/organizations/'+activeOrganization.organization_id+'/document-upload?update_doc=1';
	const showMessage = useStoreActions(actions=>actions.alertMessage.setMessage);
	const [showAllNotifications,setShowAllNotifications] = useState(false);
	const [notifications,setNotifications] = useState([]);
	const [files,setFiles] = useState(null);
	const [showCompanyInfoPopup,setShowCompanyInfoPopup] = useState(false);
	const [showOwnerInfoPopup,setShowOwnerInfoPopup] = useState(false);
	const [availableFiles,setAvailableFiles] = useState();
	const [availableFileTypes,setAvailableFileTypes] = useState();
	const [ownerDocuments,setOwnerDocuments] = useState([]);
	const [requiredDocs,setRequiredDocs] = useState([]);
	const [auditedFinancials,setAuditedFinancials] = useState(true);
	const [statusTitle,setStatusTitle] = useState(null);
	const [microdeposits,setMicrodeposits] = useState(['','']);
	const [debits,setDebits] = useState([]);
	const [loading,setLoading] = useState(true);
	const [currentPage,setCurrentPage] = useState(1);
	const [totalPages,setTotalPages] = useState(1);
	const [isScrollLoading,setIsScrollLoading] = useState(false);
	const [adpConnected,setAdpConnected] = useState(false);
	const [adpActivated,setAdpActivated] = useState(activeOrganization?.adp_account?.adp_account_activated);

	const setMessage = useStoreActions(actions=>actions.alertMessage.setMessage);

	const scrollLoad = (page) =>{
		setIsScrollLoading(true);
		let queryString = '?page='+page;
		setCurrentPage(page);
		axios.get('/organizations/'+activeOrganization['organization_id']+'/debits' + queryString).then(response=>{
			setDebits([...debits,...response.data.debits]);
			setLoading(false);
			setTotalPages(response.data.meta.pages);
			setTimeout(()=>{setIsScrollLoading(false);},0)
		}).catch(e=>{});
	};

	const updateFile = (type,value) =>{
		setFiles(files.map(f=>{
			if(f.type===type){
				f.value = value;
				f.error = !value
			}
			return f;
		}));

		if(value) {
			let fileType = availableFileTypes.filter(file=>file.organization_document_type_token === type)[0];
			let postData = {
				documents: [{
					organization_document_file: value,
					organization_document_type: type,
					organization_document_type_id:fileType.organization_document_type_id
				}],
				update_application:true
			};
			axios.post('/organizations/'+activeOrganization.organization_id+'/documents',postData).then(response=>{
			}).catch(e=>{})
		}
		setUpdateKey(Math.random());
	};

	const updateOwnerFile = (ownerId,value) =>{
		setOwnerDocuments(ownerDocuments.map(file=>{
			if(file.organization_owner_id === ownerId){
				file.value = value;
				file.error = !value
			}
			return file;
		}));
		if(value) {
			let ownerType = availableFileTypes.filter(file=>file.organization_document_type_token === 'owner_photo')[0];
			let postData = {
				documents: [{
					organization_document_file: value,
					organization_owner_id: ownerId,
					organization_document_type_id:ownerType.organization_document_type_id
				}],
				update_application:true
			};
			axios.post('/organizations/'+activeOrganization.organization_id+'/documents',postData).then(response=>{
			}).catch(e=>{})
		}
	};

	const formatRequiredDocs = (docs)=>{
		let documentSlots = [];
		docs.forEach(doc=> {
			if (doc) {

				documentSlots.push({
					type: doc.organization_document_type_token,
					label: doc.organization_document_type_title,
					type_id:doc.organization_document_type_id,
					descriptions:doc.descriptions,
					value: '',
					required: doc.organization_document_type_token !== 'dba'
				})
			}
		});
		return documentSlots;
	};

	const buildStatusTitle = (organization) =>{
		if(organization.application_approved){
			setStatusTitle('Approved');
			return;
		}
		if(organization.using_student_loans){
			let forteStatus = '';
			if(!organization.forte_approved && organization.organization_forte_application_status.toLowerCase() === 'approved'){
				forteStatus = 'Error';
			}else{
				forteStatus = organization.organization_forte_application_status;
			}
			setStatusTitle('Forte: ' +forteStatus+ ' | Spinwheel: ' + (organization.student_loan_approved?'Approved':'Pending'));
		}else{
			setStatusTitle(organization.organization_forte_application_status);
		}

	};

	useEffect(()=>{
		if(!files){
			return;
		}
		if(!auditedFinancials && requiredDocs && (requiredDocs.includes('business_financials') || files.filter(entry=>entry.type==='business_financials').length>0) && !requiredDocs.includes('tax_returns')){
			let bfIndex = 0;
			files.forEach((entry,index)=>{if(entry.type==='business_financials'){bfIndex=index; return false;}});
			files.splice(bfIndex+1,0,{
				type:'tax_returns',
				label:availableFiles.tax_returns.label
			})
			setFiles(files)
			setUpdateKey(Math.random());
		}else if(auditedFinancials && !requiredDocs.includes('tax_returns')){
			setFiles(files.filter(entry=>entry.type !== 'tax_returns'));
			setUpdateKey(Math.random());
		}
	},[auditedFinancials,requiredDocs]);

	useEffect(()=>{
		if(error){
			setMessage({message:decodeURI(error),type:'error'});
		}
	},[error])

	useEffect(()=>{
		const cancelToken = axios.CancelToken.source();
		const cancelToken2 = axios.CancelToken.source();
		const statusToken = axios.CancelToken.source();
		let newNotifications = [];
		axios.get('/organizations/'+activeOrganization.organization_id+'/application-status', {cancelToken:statusToken.token}).then(response=>{
			if(response.data.organization){
				buildStatusTitle(response.data.organization);
				setAdpConnected(response.data.organization.adp_account_linked);
			}
			if(response.data.notifications){
				let accountNotification = response.data.notifications.map(entry=>{
					let notificationType = (entry.organization_forte_notification_body.toLowerCase().indexOf('success')>-1 || entry.organization_forte_notification_body.toLowerCase().indexOf('approved')>-1 || entry.organization_forte_notification_type==='alert')?'alert':'error';
					let date = entry.organization_forte_notification_timestamp?new Date(entry.organization_forte_notification_timestamp.replace(' ','T')):'';
					let title = notificationType==='error'?'There was a problem with your application': 'Application Status';
					return {
						type: notificationType,
						message: '<strong>' + date.toLocaleDateString() + ' - '+title+':</strong> ' + entry.organization_forte_notification_body,
						sortby: date.valueOf()
					}
				})
				newNotifications = [...newNotifications,...accountNotification];
				setNotifications([...newNotifications,...notifications].sort((a,b)=>{
					if(a.sortby>b.sortby) return -1;
					if(a.sortby<b.sortby) return 1;
					return 0;
				}))
				setUpdateKey(Math.random());
			}
		}).catch(e=>{});

		axios.get('/organizations/'+activeOrganization.organization_id+'/required-documents', {cancelToken:cancelToken2.token}).then(response=>{
			if(response.data.required_documents){

				setRequiredDocs(response.data.required_documents);
				let requiredOwnerDocuments = response.data.owner_documents;

				setOwnerDocuments(requiredOwnerDocuments);
				let availableFileTypes = response.data.available_types;
				setAvailableFileTypes(availableFileTypes);
				setAvailableFiles(availableFileTypes);
				setRequiredDocs(response.data.required_documents);
				let formattedDocs = formatRequiredDocs(response.data.required_documents);

				setFiles(formattedDocs);
				setUpdateKey(Math.random());

				axios.get('/organizations/'+activeOrganization.organization_id+'/documents', {cancelToken:cancelToken.token}).then(response=>{
					if(response.data.documents && formattedDocs){
						let updatedDocs = formattedDocs.map(file=>{
							let existingFile = response.data.documents.filter(ef=>ef && file.type===ef['organization_document_type_token']);
							if(existingFile.length>0){
								file.value = existingFile[0]['organization_document_file']
							}
							return file;
						});
						setFiles(updatedDocs);
						let ownerType = availableFileTypes.filter(file=>file.organization_document_type_token === 'owner_photo')[0];
						setOwnerDocuments(requiredOwnerDocuments.map(file=>{
							let existingFile = response.data.documents.filter(ef=>ef && file.organization_owner_id===ef['organization_owner_id']);
							if(existingFile.length>0){
								file.value = existingFile[0]['organization_document_file']
							}
							file.organization_document_type_token = ownerType.organization_document_type_token;
							file.organization_document_type_id = ownerType.organization_document_type_id;
							file.descriptions = ownerType.descriptions;
							return file;
						}));
						setUpdateKey(Math.random());
					}else{
						setFiles(formattedDocs);
						setUpdateKey(Math.random());
					}
					if(response.data.documents){
						let documentNotifications = response.data.documents.filter(doc=>{return doc && doc.organization_forte_notification_timestamp}).map(entry=>{
							let date = entry.organization_forte_notification_timestamp ? new Date(entry.organization_forte_notification_timestamp.replace(' ','T')):'';
							let notificationType = entry.organization_forte_notification_body.toLowerCase().indexOf('success')>-1?'alert':'error';
							let title = entry.organization_document_type.replace('_',' ').replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
							let body = notificationType === 'error'? entry.organization_forte_notification_body : 'Document submitted successfully';
							return {
								type: notificationType,
								message: '<strong>' + date.toLocaleDateString() + ' - '+title+':</strong> ' + body,
								sortby: date.valueOf()
							}
						});
						newNotifications = [...newNotifications,...documentNotifications];
						setNotifications([...newNotifications,...notifications].sort((a,b)=>{
							if(a.sortby>b.sortby) return -1;
							if(a.sortby<b.sortby) return 1;
							return 0;
						}))
						setUpdateKey(Math.random());
					}
				}).catch(e=>{});
			}
		}).catch(e=>{});
		axios.get('/organizations/'+activeOrganization['organization_id']+'/debits').then(response=>{
			setDebits(response.data.debits)
			setCurrentPage(response.data.meta.page);
			setTotalPages(response.data.meta.pages);
			setLoading(false)
		}).catch(e=>{console.log(e)})


		return ()=>{
			cancelToken.cancel('');
			cancelToken2.cancel('');
		}
	},[]);


	const submitMicrodeposits = () =>{
		let postData = {
			funding_account: {
				funding_account_deposit_1: microdeposits[0].trim(),
				funding_account_deposit_2: microdeposits[1].trim()
			}
		};
		if(postData.funding_account.funding_account_deposit_1.length === 0 || postData.funding_account.funding_account_deposit_2.length === 0){
			showMessage({message:'Both deposit amounts are required',type:'error'});
		}
		axios.post('/organizations/'+activeOrganization.organization_id+'/funding', postData).then((response)=>{

		}).catch(e=>{});
	};

	const activateAdp = () =>{
		axios.post('/organizations/'+activeOrganization.organization_id+'/activate-adp').then((response)=>{
			setAdpActivated(true);
		}).catch(e=>{});
	}

	const resendInvites = () =>{
		axios.post('/organizations/'+activeOrganization.organization_id+'/users/resend-employee-invites').then((response)=>{
			showMessage({message:'Invites resent',type:'success'});
		}).catch(e=>{});
	}

	return (
		<>

			{showCompanyInfoPopup && <CompanyInformationPopup closeHandler={()=>{setShowCompanyInfoPopup(false)}} />}
			{showOwnerInfoPopup && <OwnerInformationPopup closeHandler={()=>{setShowOwnerInfoPopup(false)}} />}
		<div className={'full-width page company-account-status'}>
			<div className={'header'}>
				<div>
					<h1 className={'heading'} >
						Account Status
						<span className={'account-status'}>
							<img src={'/images/horiz-dots-white.svg'} alt={''} />
							{statusTitle}
						</span>
					</h1>

				</div>

			</div>
			<div className={'list-section'}>
				<div className={'body'}>
					<div style={{marginBottom:45,display:"flex", justifyContent:"space-between", alignItems:"center", flexWrap:"wrap"}}>
						<h2 >Program ID: {activeOrganization['organization_uid']}</h2>
						<div style={{display:"flex", gap:"2.5rem", maxWidth:"80%", flexWrap:"wrap"}}>
							{(adpConnected && !adpActivated) &&
							<button onClick={activateAdp} className={'simple-rounded-button blue'}>
								<span>Activate ADP invites</span>
							</button>
							}

							<button onClick={resendInvites} className={'simple-rounded-button green'}>
								<span>Resend Pending Invites</span>
							</button>

						</div>
					</div>
					{notifications.length > 0 &&
					<>
						<h2>Account</h2>
						<div className={'notification '+notifications[0].type} dangerouslySetInnerHTML={{__html:notifications[0].message}} />
					</>
					}
					{notifications.length > 1 &&
						<>
							<span onClick={()=>{setShowAllNotifications(!showAllNotifications)}} className={'clickable toggle-notifications'}>{showAllNotifications?'- Hide Notifications':'+ Show All Notifications'}</span>
							<CSSTransition
								in={showAllNotifications}
								timeout={{
									appear: 500,
									enter: 500,
									exit: 100,
								}}
								classNames="slide-down"
								unmountOnExit
							>
								<>
									{notifications.map((notification,index)=>{
										if(index===0){
											return null;
										}
										return(<div key={index} className={'notification '+notification.type} dangerouslySetInnerHTML={{__html:notification.message}} />)
									})}
								</>
							</CSSTransition>
						</>
					}
					{(files && files.length > 0) &&
					<>
						<h2>
						Documents
					</h2>
					<div className={'document-list-block'}>
						{files.map((entry,index)=>{
								if(entry.type==='business_financials'){
									return (
										<div key={entry.type+index}>
											<div className={'label'}>
												Business Financials*
												<div  className={'document-options-selector'}>
													<div className={'check-holder '} onClick={() => {
														setAuditedFinancials(!auditedFinancials);
													}}>
														Financials are audited
														<span className={'checkbox ' + (auditedFinancials ? 'checked ' : ' ')}/>
													</div>

												</div>
												<FileInput
													fullRow={true}
													label={entry.label}
													fileKey={entry.type}
													uploadUrl={uploadUrl}
													error={entry.error}
													changeHandler={updateFile}
													existingValue={entry.value}/>
											</div>

										</div>
									)
								}
								return (
									<FileInput
										fullRow={true}
										key={entry.type+index}
										label={entry.label}
										fileKey={entry.type}
										descriptions={entry.descriptions}
										uploadUrl={uploadUrl}
										error={entry.error}
										optional={!entry.required}
										changeHandler={updateFile}
										existingValue={entry.value} />
								)
							}
						)}
					</div>
					{ownerDocuments.length > 0 &&
					<>
						<br/>
							<h2>Photo IDs are required for the following owners or representatives:</h2>

						<div className={'form-container'}>
							<div className={'document-list-block'}>
								{ownerDocuments.map((document, index) => {
										return (<FileInput
											fullRow={true}
											key={(document.organization_document_type_token || document.type) + index.toString()}
											label={document.display_name}
											descriptions={document.descriptions}
											fileKey={document.organization_owner_id}
											uploadUrl={uploadUrl}
											error={document.error}
											changeHandler={updateOwnerFile}
											existingValue={document.value}/>)
									}
								)}
							</div>
						</div>
					</>
					}
					</>
					}
					{(activeOrganization && activeOrganization.funding_account_id && activeOrganization.using_student_loans && !activeOrganization.student_loan_approved) &&
					<>
						<br/>
						<h2>
							Verify Micro-deposits
						</h2>
						<div className={'form-container'}>
							<div className={'document-list-block field-list'}>
								<label>
									First Deposit:
									<input value={microdeposits[0]} onChange={(e)=>{setMicrodeposits([e.target.value,microdeposits[1]])}} placeholder={'$0.00'}/>
								</label>
								<label>
									Second Deposit:
									<input value={microdeposits[1]} onChange={(e)=>{setMicrodeposits([microdeposits[0],e.target.value])}} placeholder={'$0.00'}/>
								</label>
								<div className={'full-row right'}>
									<button onClick={submitMicrodeposits} className={'simple-rounded-button purple'}><span>Submit</span></button>
								</div>
							</div>
						</div>
						<div className={'cancel-instructions'}>
							* If you need to suspend this program and remove the linked funding source use the contact form below, or email us at <a href="mailto:support@ThriveMatching.com" target="_blank" rel={'noreferrer noopener'}>support@ThriveMatching.com</a>.
						</div>
					</>
					}

					<AdpDetails />

					{(debits && debits.length > 0) &&
						<Tabs
							tabs={[
								{
									title: 'Daily Debit Reports',
									content:
										<InfiniteScroll
											pageStart={1}
											loadMore={isScrollLoading ? null : scrollLoad}
											initialLoad={false}
											hasMore={(currentPage < totalPages)}
											loader={<div className="loader" key={0}>Loading ...</div>}
										>
											<DebitsList files={debits} linkClass={'invoice'}/>
										</InfiniteScroll>
								},
								{
									title: 'Generate Debit Report',
									content: <DebitReportForm/>
								}
							]}
							style={{marginTop: 50}}
						/>
					}
					<a style={{display:'block',marginTop:'20px',width:'fit-content'}}
					   href={'#'} onClick={(e)=>{
						e.preventDefault();
						axios.get('/auth/generate-token').then(response=>{
							if(response.data.token){
								let filePath = baseApiUrl+'/organizations/'+activeOrganization.organization_id+'/download-terms?auth-token='

								window.open(filePath+response.data.token, '_blank');
							}
						}).catch(e=>{})
					}} >Download Terms of Service</a>
				</div>

			</div>

		</div>
		</>
	)
}
