import React, {useState, useCallback, useMemo} from 'react';
import DatePicker from "react-datepicker";
import axios from "axios";

import {processors, Colors} from "../_constants";
import SingleSelector from "./singleSelector";
import {useStoreState} from "easy-peasy";
import {getBaseApiUrl} from "../_helpers";

export default function DebitReportForm() {
	const [fromDate, setFromDate] = useState(null);
	const [toDate, setToDate] = useState(null);
	const [processor, setProcessor] = useState(null);
	const activeOrganization = useStoreState(state => state.user.activeOrganization);

	const processorsFormatted = useMemo(() => {
		return processors.map((processor) => {
			return {
				label: processor,
				value: processor
			};
		})
	}, [processors]);

	const handleSubmit = useCallback((type) => {
		axios.get('/auth/generate-token').then(response => {
			if (response.data.token) {
				window.open(`${getBaseApiUrl()}/organizations/${activeOrganization.organization_id}/debits/generate-download/?fromDate=${fromDate.getTime() / 1000}&toDate=${toDate.getTime() / 1000}&processor=${processor}&auth-token=${response.data.token}&type=${type}`, '_blank');
			}
		})
	}, [getBaseApiUrl, activeOrganization.organization_id, fromDate, toDate, processor]);

	return (
		<form onSubmit={handleSubmit}>
			<div style={{display: 'flex', gap: 10, marginBottom: 10}}>
				<label>
					From Date
					<DatePicker
						selected={fromDate}
						onChange={date => {
							setFromDate(date);
						}}
						onSelect={date => {
							setFromDate(date);
						}}
					/>
				</label>
				<label>
					To Date
					<DatePicker
						selected={toDate}
						onChange={date => {
							setToDate(date);
						}}
						onSelect={date => {
							setToDate(date);
						}}
					/>
				</label>
			</div>
			<label style={{marginBottom: 10, display: 'block'}}>
				Processor
				<SingleSelector
					options={processorsFormatted}
					changeHandler={processor => {
						setProcessor(processor);
					}}
				/>
			</label>
			<div className={'save-row'} style={{display: 'flex', gap: 10}}>
				<button style={{backgroundColor: Colors.blue, color: '#FFF'}}
				        className={'simple-rounded-button'}
				        onClick={e => {
					        e.preventDefault();
					        handleSubmit('pdf');
				        }}
				>
					<span>Download Report PDF</span>
				</button>
				<button style={{backgroundColor: Colors.blue, color: '#FFF'}}
				        className={'simple-rounded-button'}
				        onClick={e => {
					        e.preventDefault();
					        handleSubmit('csv');
				        }}
				>
					<span>Download Report CSV</span>
				</button>
			</div>
		</form>
	);
}
