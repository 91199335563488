import React, {useState, useEffect, useRef} from 'react';
import Popup from "../../../components/popups/popup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useLocation} from "react-router";
import SingleSelector from "../../../components/singleSelector";
import axios from 'axios';
import {useStoreActions, useStoreState} from "easy-peasy";
import InfiniteScroll from "react-infinite-scroller";
import NoResults from "../../../components/noResults";
import {EMPLOYEE_TYPE} from "../../../_constants";
import NumberFormat from "react-number-format";
import PopupSubNav from "../../../components/nav/popupTabNav";
import {EmployeePopupSubMenu} from "../../../_menus";
import ActivityPopup from "../../../components/popups/activityPopup";
import DownloadCsv from '../../../components/DownloadCsv';

export default function EmployeePopup({closeHandler,employee,updateHandler,accentColor,deleteHandler}){
    const location = useLocation();
    const [activeScreen,setActiveScreen] = useState('settings');
    const [curEmployee,setCurEmployee] = useState(employee?JSON.parse(JSON.stringify(employee)):null);
    const [startDate,setStartDate] = useState(new Date(employee? employee.user_organization_start_date + 'T12:00:00'  : new Date()));
    const [employeeUpdated,setEmployeeUpdated] = useState('');
    const activeOrganization = useStoreState(state=>state.user.activeOrganization);
    const setMessage = useStoreActions(actions=>actions.alertMessage.setMessage);
    const [roleInfo,setRoleInfo] = useState(null)
    const [contributionSettings,setContributionSettings] = useState(null);
    const [activities,setActivities] = useState([]);
    const [currentPage,setCurrentPage] = useState(1);
    const [totalPages,setTotalPages] = useState(1);
    const [isScrollLoading,setIsScrollLoading] = useState(false);
    const [updateKey,setUpdateKey] = useState(Math.random());
    const [employeeTypes,setEmployeeTypes] = useState(null);
    const [showActivityPopup,setShowActivityPopup] = useState(false);
    const [activityType,setActivityType] = useState(null);
    const [statusOptions,setStatusOptions] = useState(null);
    const [selectedActivity,setSelectedActivity] = useState(null);

    const activityHolderRef = useRef();

    const showActivityDetails = (activity) =>{
        setSelectedActivity(activity);
        setShowActivityPopup(true);
    };

    useEffect(()=>{
        if(activeScreen !== 'settings'){
            let type;
            switch(activeScreen){
                case 'contributions':
                    type = ['contribution-settings-updated'];
                    break;
                case 'accounts':
                    type = ['account-created','allocation-updated'];
                    break;
                case 'payments':
                    type = ['payment-initiated','payment-pending','payment-paid','payment-error','payment-retry'];
                    break;
                default:
                    type = [];
            }
            setActivityType(type);
            let cancelToken = axios.CancelToken.source();
            getNewActivity(type,cancelToken);

            return ()=>{
                cancelToken.cancel('');
            }
        }

    },[activeScreen]);

    const updateEmployee = (key,value) =>{
        if(curEmployee[key] !== value) {
            curEmployee[key] = value;
            setCurEmployee(curEmployee);
            setEmployeeUpdated(Math.random());
        }
    };

    const sendInvite = () =>{
        axios.post('/organizations/'+activeOrganization.organization_id+'/users/'+employee.user_id+'/resend-invite/'+EMPLOYEE_TYPE).then(response=>{
            if(!response.data.meta.message) {
                setMessage({message: 'Invitation Sent'});
            }
        }).catch(e=>{});
    };

    const getEmployeeRoleInfo = () =>{
        if(!curEmployee){
            setStatusOptions([
                {label:'Active',value:'active',selected:false},
                {label:'Inactive',value:'inactive',selected:false}
            ]);
            return;
        }
        let activeRole = curEmployee.roles.filter(role=>role.organization_id===activeOrganization.organization_id && role.role_token===EMPLOYEE_TYPE);
        if(activeRole.length>0){
            setRoleInfo(activeRole[0]);
            setStatusOptions([
                {label:'Active',value:'active',selected:activeRole[0].user_organization_role_status === 'active'},
                {label:'Inactive',value:'inactive',selected:activeRole[0].user_organization_role_status === 'inactive'}
            ])
            setUpdateKey(Math.random());
        }
    };

    const updateStatus = (status) =>{
      updateEmployee('user_organization_role_status',status);
    };

    const saveEmployee = (e) =>{
        e.preventDefault();
        updateHandler(curEmployee);
    };

    const deleteUser = (e) =>{
        e.preventDefault();
        deleteHandler(curEmployee);
    };

    const scrollLoad = () =>{
        if(currentPage+1 > totalPages){
            return false;
        }

        setIsScrollLoading(true);
        let typeSearch = '';
        if(activityType && Array.isArray(activityType)){
            typeSearch = '&';
            activityType.forEach((type,index)=>{
                if(index !== 0){
                    typeSearch += '&';
                }
                typeSearch += 'types[]='+type;
            })
        }
        let queryString = '?page='+(currentPage+1);
        setCurrentPage(currentPage+1);
        axios.get('/organizations/'+activeOrganization.organization_id+'/users/'+curEmployee.user_id+'/activity' + queryString+typeSearch).then(response=>{
            setActivities([...activities,...response.data.activity]);
            setTotalPages(response.data.meta.pages);
            setTimeout(()=>{setIsScrollLoading(false);},0)
        }).catch(e=>{});
    };
    const handleDateChangeRaw = (e) => {
        let date = new Date(e.target.value);
        if(date.getTime() === date.getTime()) {
            updateEmployee('user_organization_start_date', date.toISOString());
        }
        // e.preventDefault();
        // e.preventDefault();
    };

    useEffect(()=>{
        if(curEmployee) {
            let date = startDate?startDate.toISOString():'';
            updateEmployee('user_organization_start_date',date);
        }
    },[curEmployee,startDate]);

    useEffect(()=>{
        if(!employee){
            let newEmployee = {
                profile_first_name:'',
                profile_last_name:'',
                user_email:'',
                start_date:'',
                invite_date:'',
                signup_date:'',
                id_number:'',
                status:'',
                user_contribution_setting_eligible_compensation:0,
                user_contribution_setting_retirement_amount:0,
                contribution_tier_requirement:''
            };
            if(activeOrganization.plan_token === 'employer-direct'){
                newEmployee.user_contribution_setting_override_amount = 0.00;
            }
            setCurEmployee(newEmployee);
        }else{
            getEmployeeRoleInfo()
        }
    },[employee, activeOrganization]);

    const getNewActivity = (type = null,cancelToken = null) =>{
        setIsScrollLoading(false);
        if(activityHolderRef.current) {
            activityHolderRef.current.scrollTo(0,0);
        }
        let newType = type || activityType;
        let typeSearch = '';
        if(newType && Array.isArray(newType)){
            typeSearch = '?';
            newType.forEach((type,index)=>{
                if(index !== 0){
                    typeSearch += '&';
                }
                typeSearch += 'types[]='+type;
            })
        }
      let config = cancelToken? {cancelToken:cancelToken.token}: null;
        axios.get('/organizations/'+activeOrganization.organization_id+'/users/'+curEmployee.user_id+'/activity'+typeSearch, config).then(response=>{
            setActivities(response.data.activity);
            setCurrentPage(response.data.meta.page);
            setTotalPages(response.data.meta.pages);
            setUpdateKey(Math.random())
        }).catch(e=>{});
    };

    useEffect(()=>{

        let contributionCancelToken = axios.CancelToken.source();
        if(activeOrganization.plan_token==='employer-direct'){
            axios.get('/organizations/'+activeOrganization.organization_id, {cancelToken:contributionCancelToken.token}).then(response=>{
                setContributionSettings(response.data.organization);
                if(response.data.organization.organization_contribution_setting_tier_type === 'employee-type' && response.data.organization.contribution_tiers) {
                    let types = response.data.organization.contribution_tiers.map(tier=>{
                        let option = {
                            label:tier.contribution_tier_requirement,
                            value:tier.contribution_tier_requirement
                        }
                        if(curEmployee && curEmployee['contribution_tier_id'] === tier.contribution_tier_id){
                            option.selected = true;
                        }
                        return option;
                    });
                    if(curEmployee && !curEmployee['contribution_tier_id']){
                        types.push(
                            {
                                label:'None (select type)',
                                value:null,
                                selected:true
                            }
                        );
                    }
                    setEmployeeTypes(types)
                }
            })
        }
        if(!curEmployee){
            return
        }
        getEmployeeRoleInfo();
        let cancelToken = axios.CancelToken.source();
        getNewActivity(cancelToken);
        return ()=>{
            cancelToken.cancel('');
            contributionCancelToken.cancel('');
        }
    },[]);
    return(
        <Popup closeHandler={closeHandler} tabbed={true}>
            {showActivityPopup &&
                <ActivityPopup activity={selectedActivity} closeHandler={()=>{setShowActivityPopup(false)}} />
            }
            <PopupSubNav menu={EmployeePopupSubMenu} changeHandler={setActiveScreen} currentScreen={activeScreen} />
            {(activeScreen === 'settings' && curEmployee) &&
                <div className={'employee-settings popup-content'}>
                    <h2 style={{color:accentColor}} className={'title'}>Employee Account</h2>
                    <div className={'popup-form employee'}>
                        <label>
                            First Name
                            <input
                                value={curEmployee.profile_first_name}
                                onChange={(e)=>{updateEmployee('profile_first_name',e.target.value)}}
                                placeholder={'First Name'}/>
                        </label>

                        <label>
                            Last Name
                            <input
                                value={curEmployee.profile_last_name}
                                onChange={(e)=>{updateEmployee('profile_last_name',e.target.value)}}
                                placeholder={'Last Name'}/>
                        </label>

                        <label>
                            Email
                            <input
                                value={curEmployee.user_email}
                                onChange={(e)=>{updateEmployee('user_email',e.target.value)}}
                                placeholder={'email'}/>
                        </label>

                        <label>
                            Start Date
                            <DatePicker onChangeRaw={handleDateChangeRaw} selected={startDate} onChange={date=>setStartDate(date)} onSelect={(date)=>{setStartDate(date)}}/>
                        </label>

                        {curEmployee.user_organization_timestamp &&
                            <label>
                                Original Invite Date
                                <div
                                    className={'read-only'}>{new Date(curEmployee.user_organization_timestamp.replace(' ','T')).toLocaleDateString() || 'Invite not sent'}</div>
                            </label>
                        }
                        {roleInfo &&
                        <label>
                            Signup Date
                            <div className={'read-only'}>
                                <span>{roleInfo.user_organization_role_timestamp_accepted? new Date(roleInfo.user_organization_role_timestamp_accepted).toLocaleDateString() : 'Invite Not Accepted'}</span>
                                {!roleInfo.user_organization_role_timestamp_accepted &&
                                <span style={{color: accentColor}} className={'clickable standard-link'}
                                      onClick={() => {
                                          sendInvite()
                                      }}>Resend Invite</span>}
                            </div>
                        </label>
                        }

                        <label>
                            Employee ID
                            {!!employee &&
                                <div className={'read-only'}>
                                    <span>{curEmployee.user_organization_number || 'No ID Assigned'}</span>
                                </div>
                            }
                            {!employee &&
                                <input
                                    value={curEmployee.user_organization_number}
                                    onChange={(e)=>{updateEmployee('user_organization_number',e.target.value)}}
                                    placeholder={'Employee ID'}/>
                            }
                        </label>

                        {!!employee &&
                        <label>
                            Employee Status
                            {statusOptions &&
                                <SingleSelector options={statusOptions}
                                                showStatusMarkers={true}
                                                changeHandler={updateStatus} />
                            }
                        </label>
                        }
                        {(activeOrganization && activeOrganization.plan_token !== 'employer-direct') &&
                        <label>
                            <span style={{textTransform:'capitalize'}}>{(activeOrganization && activeOrganization.contribution_frequency)?activeOrganization.contribution_frequency.replace('-',' '):''} Income</span>
                            <NumberFormat
                                value={curEmployee.user_contribution_setting_eligible_compensation === null ? 0 : curEmployee.user_contribution_setting_eligible_compensation}
                                thousandSeparator={true}
                                decimalScale={0}
                                prefix={'$'}
                                onValueChange={(values) => {
                                    const {formattedValue, value} = values;
                                    updateEmployee('user_contribution_setting_eligible_compensation', value)
                                }}
                            />
                        </label>
                        }
                        {(activeOrganization && activeOrganization.plan_token === 'flexible-match') &&

                        <label>
                            401k contribution
                            <NumberFormat
                                value={curEmployee.user_contribution_setting_retirement_amount === null? 0 :curEmployee.user_contribution_setting_retirement_amount}
                                thousandSeparator={true}
                                decimalScale={0}
                                suffix={'%'}
                                onValueChange={(values) => {
                                    const {formattedValue, value} = values;
                                    updateEmployee('user_contribution_setting_retirement_amount', value)
                                }}
                            />
                        </label>
                        }
                        {(contributionSettings && contributionSettings.organization_contribution_setting_tier_type === 'employee-type' && employeeTypes) &&
                            <label>
                                Employee Type
                                <SingleSelector options={employeeTypes}
                                                showStatusMarkers={false}
                                                changeHandler={tierRequirement=>updateEmployee('contribution_tier_requirement',tierRequirement)} />

                            </label>
                        }
                        {(curEmployee && activeOrganization && activeOrganization.plan_token === 'employer-direct') &&
                        <label>
                            Override Payment Amount
                            <NumberFormat
                                value={curEmployee.user_contribution_setting_override_amount === null? 0 :curEmployee.user_contribution_setting_override_amount}
                                thousandSeparator={true}
                                decimalScale={2}
                                prefix={'$'}
                                onValueChange={(values) => {
                                    const {formattedValue, value} = values;
                                    updateEmployee('user_contribution_setting_override_amount', value)
                                }}
                                />

                        </label>
                        }
                        <div className={'save-row'}>
                            <button style={{backgroundColor:accentColor,color:'#FFF'}} className={'simple-rounded-button'} onClick={(e)=>{saveEmployee(e)}}><span>Save Changes</span></button>
                            <button style={{backgroundColor:'#F67281',color:'#FFF', marginLeft:'20px'}} className={'simple-rounded-button'} onClick={(e)=>{deleteUser(e)}}><span>Delete</span></button>
                        </div>
                    </div>
                </div>
            }
            {(activeScreen !== 'settings' && curEmployee) &&
                <div className={'employee-activity popup-content'} ref={activityHolderRef} style={{position: 'relative'}}>
                    <h2 style={{color:accentColor}} className={'title'}>Employee Account</h2>
                    {activeScreen === 'payments' &&
                        <DownloadCsv
                            url={`/organizations/${activeOrganization.organization_id}/users/${employee.user_id}/payment-history-csv`}
                            label="Download Payment History"
                            downloadName="Employee Payments.csv"
                            className={'sub-header'}
                            apiParams={{admin: 1}}
                            style={{cursor: 'pointer', position: 'absolute', top: 65}}
                        />
                    }
                    <InfiniteScroll
                        useWindow={false}
                        pageStart={1}
                        loadMore={isScrollLoading?null:scrollLoad}
                        initialLoad={false}
                        hasMore={(currentPage<totalPages)}
                        loader={<div className="loader" key={0}>Loading ...</div>}
                        getScrollParent={()=>activityHolderRef.current}
                    >
                    <div className={'activity-list'}>
                        <div className={'dashboard-list'}>
                            {activities.length>0 &&
                            <>
                                {location.state.layout !== 'mobileMax' &&
                                        <div className={'table-row file-row header'}>
                                            <span>Action</span>
                                            <span>Date</span>
                                        </div>
                                }
                                {location.state.layout!=='mobileMax' &&  activities.map((activity,index)=>{
                                    if(!activity.activity_message){
                                        return null;
                                    }
                                    return (
                                    <div key={index} className={'table-row  file-row clickable'} onClick={()=>{showActivityDetails(activity)}}>
                                    <span >{activity.activity_message}</span>
                                    <span >{activity.activity_timestamp?new Date(activity.activity_timestamp.replace(' ','T')).toLocaleDateString():''}</span>
                                    </div>
                                    )
                                })}
                                {(location.state.layout==='mobileMax')  && activities.map((activity,index)=>{
                                    if(!activity.activity_message){
                                        return null;
                                    }
                                    return (
                                    <div key={index} className={'table-row  file-row activity tablet clickable'} onClick={()=>{showActivityDetails(activity)}}>
                                    <div>
                                    <span className={'title'}>Action</span>
                                    <span >{activity.activity_message}</span>
                                    </div>
                                    <div>
                                    <span className={'title'}>Date</span>
                                    <span >{activity.activity_timestamp?new Date(activity.activity_timestamp.replace(' ','T')).toLocaleDateString():''}</span>
                                    </div>
                                    </div>
                                    )
                                })}
                                {activities.length===0 && <NoResults/>}
                            </>
                            }
                        </div>
                    </div>
                    </InfiniteScroll>
                </div>
            }
        </Popup>
    )
}
