import React, {useState} from 'react';

export default function Tabs({
	                             currentTab = 0,
	                             tabs = [],
	                             style = {}
                             }) {

	const [activeIndex, setActiveIndex] = useState(currentTab);

	return (
		<>
			<div className="tab-container" style={style}>
				{
					tabs.map((tab, index) => {
						return (
							<div
								key={index}
								className={`tab${activeIndex === index ? ' active' : ''}`}
								onClick={(e) => {
									e.stopPropagation();
									setActiveIndex(index);
								}}
							>
								<span>{tab.title}</span>
							</div>
						);
					})
				}
			</div>
			<div className="tab-content-container">
				{
					tabs.map((tab, index) => {
						return (
							<div
								key={index}
								className={`tab-content${activeIndex === index ? ' active' : ''}`}>
								{tab.content}
							</div>
						);
					})
				}
			</div>
		</>
	);
}
